<template>
	<section class="crear-cupones-vendedor">
		<!-- actions -->
        <div class="row mx-0 py-2">
			<div class="col-12 col-lg-8">
				<div class="row">
					<div class="col-auto">
						<div class="btn-back" @click="$router.push({name: 'tendero.cupones'});">
							<i class="icon-left f-12" />
						</div>
					</div>
					<div class="col pl-0 text-general f-18">
						Detalle del cupón
					</div>
					<div class="col-auto d-middle ml-auto">
						<span class="text-general mx-2">
							Cupon {{ data.estado ? 'Activo' : 'Inactivo' }}
						</span>
						<el-switch v-model="data.estado" :active-value="1" :inactive-value="0" active-color="#000000" @change="cambiarEstadoCupon" />
					</div>
					<div class="col-auto d-middle px-1">
						<el-tooltip placement="bottom" content="Mover de carpeta" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-folder-move-outline f-22 hover-icons" @click="moverCarpeta" />
							</div>
						</el-tooltip>
					</div>
					<div class="col-auto d-middle px-1">
						<el-tooltip placement="bottom" content="Duplicar cupón" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-fusionar f-22 hover-icons" @click="duplicarCupon" />
							</div>
						</el-tooltip>
					</div>
					<div class="col-auto d-middle px-1">
						<el-tooltip placement="bottom" content="Cambiar nombre" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-pencil-outline f-22 hover-icons" @click="editarCupon" />
							</div>
						</el-tooltip>
					</div>
					<!-- <div class="col-auto d-middle px-1">
						<el-tooltip placement="bottom" content="Eliminar cupón" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-trash-empty f-22 hover-icons" @click="eliminarCupon" />
							</div>
						</el-tooltip>
					</div> -->
				</div>
			</div>
        </div>
        <div class="custom-scroll overflow-auto pt-3">
			<!-- indicadores -->
			<div class="row mx-0">
				<div class="col-12 col-lg-4">
					<div class="bg-white br-8 py-2 px-4 d-middle-bt">
						<p class="f-14 f-300 text-general">Disponibles</p>
						<p v-if="data.limite_total != null" class="f-30 f-500 text-general"> {{data.limite_total - data.cant_usada}} </p>
						<p v-else class="f-30 f-500 text-general"> Sin limite </p>
					</div>
				</div>
				<div class="col-12 col-lg-4">
					<div class="bg-white br-8 py-2 px-4 d-middle-bt">
						<p class="f-14 f-300 text-general">Tomados</p>
						<p class="f-30 f-500 text-general">{{ data.cant_usada }}</p>
					</div>
				</div>
			</div>
			<!-- generales -->
            <div class="row mx-0 my-5">
				<div class="col-12 col-lg-8">
					<div class="bg-white br-8 py-3 px-5">
						<p class="f-18 f-500 text-general mb-3">Generales</p>
						<!-- nombre + codigo -->
						<div class="row">
							<div class="col-5">
								<div class="d-flex">
									<i class="icon-cupon text-general mr-3" />
									<div>
										<p class="f-15 text-general">Nombre del cupón:</p>
										<p class="f-14 fr-light">{{ data.nombre }}</p>
									</div>
								</div>
							</div>
							<div class="col-5">
								<div class="d-flex">
									<i class="icon-cupon-porcentaje text-general mr-3" />
									<div>
										<p class="f-15 text-general">Código:</p>
										<p class="f-14 fr-light">{{ data.codigo }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- mensaje de cupon -->
						<div class="row my-4">
							<div class="col-12">
								<div class="d-flex">
									<i class="icon-message-text text-general mr-3" />
									<div>
										<p class="f-15 text-general">Mensaje del cupón</p>
										<p class="f-14 fr-light">{{ data.descripcion }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- descuento + limite -->
						<div class="row">
							<div class="col-5">
								<div class="d-flex">
									<i class="icon-icon-sale-outline text-general mr-3" />
									<div>
										<p class="f-15 text-general">Descuento %</p>
										<p class="f-14 fr-light">{{ data.descuento }}%</p>
									</div>
								</div>
							</div>
							<div class="col-5">
								<div class="d-flex">
									<i class="icon-arrow-collapse-horizontal text-general mr-3" />
									<div>
										<p class="f-15 text-general">Limite de uso de cupón:</p>
										<p v-if="data.limite_total != null" class="f-14 fr-light">{{ data.limite_total }}</p>
										<p v-else class="f-14 fr-light">Sin limite</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- limites del uso del cupon -->
            <div class="row mx-0">
				<div class="col-12 col-lg-8">
					<div class="bg-white br-8 py-3 px-5">
						<p class="f-18 f-500 text-general mb-3">Limites del uso del cupón</p>
						<!-- desc max -->
						<div class="row my-4">
							<div class="col-12">
								<div class="d-flex">
									<i class="icon-cash-plus text-general mr-3 f-20" />
									<div>
										<p class="f-15 text-general">Descuento máximo aplicado:</p>
										<p class="f-14 fr-light">$ {{ redondearNumero(data.compra_maxima) }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- fecha vigencia -->
						<div class="row my-4">
							<div class="col-12">
								<div class="d-flex">
									<i class="icon-calendar-clock text-general mr-3 f-20" />
									<div>
										<p class="f-15 text-general">Vigencia:</p>
										<!-- <p class="f-14 fr-light">De 27 Julio de 2020 - 10:00 a.m. Hasta De 30 Julio de 2020 - 10:00 a.m.</p> -->
										<p class="f-14 fr-light">De {{ formatearFecha(data.fecha_inicio,'DD MMM Y hh:mm A') }} hasta {{ formatearFecha(data.fecha_fin,'DD MMM Y hh:mm A') }} </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
		<!-- partials:modals -->
		<modal-mover-cupon-carpeta-vendedor ref="refModalMoverCuponCarpetaVendedor" />
		<modal-duplicar-cupon-vendedor ref="refModalDuplicarCuponVendedor" />
		<modal-eliminar ref="refModalEliminarCupon" titulo="Eliminar cupón" mensaje="¿Desea eliminar el cupón?" @eliminar="handleDeleteCoupon" />
    </section>
</template>

<script>
import Service from "~/services/cupones/cupones";
export default {
	components: {
        modalMoverCuponCarpetaVendedor: () => import('./partials/modalMoverCuponCarpetaVendedor'),
        modalDuplicarCuponVendedor: () => import('./partials/modalDuplicarCuponVendedor')
    },
	data() {
		return {
			data: {
			},
			value1:'',
		}
	},
	mounted() {
		this.getCuponDetalle()
	},
	methods: {
		async cambiarEstadoCupon(){
			try {
				await Service.cambiarEstado(this.$route.params.idCupon)
				this.notificacion('Éxito', 'Cupón actualizado correctamente', 'success')
			} catch (e) {
				return this.error_catch(e)
			}
		},
		moverCarpeta(){
			this.$refs.refModalMoverCuponCarpetaVendedor.toggle()
		},
		duplicarCupon(){
			this.$refs.refModalDuplicarCuponVendedor.toggle()
		},
		editarCupon(){
			this.$router.push({ name: 'tendero.cupones.editar', params: { idCupon: this.$route.params.idCupon } })
		},
		eliminarCupon(){
			this.$refs.refModalEliminarCupon.toggle()
		},
		async handleDeleteCoupon(){
			await Service.removerCupon(this.$route.params.idCupon)
			this.$refs.refModalEliminarCupon.toggle()
			this.$router.push({ name: 'tendero.cupones' })
		},
		async getCuponDetalle(){
			try {
				const { data } = await Service.getDatosBasicos(this.$route.params.idCupon)
				this.data = data.cupon
 			} catch (e) {
				return this.error_catch(e)
			}

		}
	}
}
</script>

<style lang="scss" scoped>
.crear-cupones-vendedor {
	background-color: inherit;
}

</style>